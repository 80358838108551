<template>
  <div>
    <navbar title="收货地址管理"></navbar>
    <div class="con">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index" class="item">
          <van-swipe-cell>
            <div class="item-con">
              <div class="item-con-c" @click="setChoose(item)">
                <div class="item-con-cell-d">{{item.detailed_area}}</div>
                <div class="item-con-cell-d">{{item.address}}</div>
                <div class="item-con-cell-c">{{item.consignee}}  {{item.mobilephone}}</div>
              </div>
              <div>
                <img class="edit-pic" :src="editPic" @click="edit(item.id)">
              </div>
              <div class="default-tips" v-if="item.is_default">默认</div>
            </div>
            <template #right>
              <div class="del-item" @click="del(item.id, index)">删除</div>
            </template>
          </van-swipe-cell>
        </div>
      </van-list>
    </div>
    <div class="footer">
      <div class="add-btn" @click="add()">
        <van-icon name="add-o" color="#fff" :size="$px2vw(36)"/>
        <div class="add" @click="add">添加收货地址</div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import EditPic from '@/assets/images/icon/edit.png'
import { addressList, addressDelete } from "@/api/address.js";
export default {
  name: 'shippingAddress',
  components: {
    Navbar
  },
  data() {
    return {
      editPic: EditPic,
      loading: false,
      finished: false,
      list: [],
      action: 0,
    }
  },
  mounted(){
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.listernerBack, false);
    }
  },
  methods: {
    onLoad() {
      this.loading = true;
      let data = { is_default: 0 };
      addressList(data).then(res => {
        if (res.code == 0) {
          this.list = res.data;
          this.finished = true;
          this.loading = false;
        } else {
          this.$toast(res.msg);
        }
      })
    },
    del(id, index) {
      addressDelete({id: id}).then(res=>{
        if(res.code == 0){
          this.list.splice(index, 1)
        }else{
          this.$toast(res.msg);
        }
      })
    },
    add() {
      let name = 'addressDetail'
      if (this.$route.name == 'orderShippingAddress') name = 'orderAddressDetail'
      this.$router.push({name: name})
    },
    edit(id){
      let name = 'addressDetail'
      if (this.$route.name == 'orderShippingAddress') name = 'orderAddressDetail'
      this.$router.push({name: name, query: { id: id }});
    },
    setChoose(item) {
      if (this.$route.name == 'orderShippingAddress') {
        this.$emit('choose', {val: item, type: 'address'})
      }
    },
    listernerBack() {
      this.setChoose(null)
      if (this.$route.name != 'orderShippingAddress') {
        this.$router.go(-1)
      }
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.listernerBack, false);
  }
}
</script>

<style lang="scss" scoped>
.con{
  @include safe-area-inset-bottom('140px');
  :deep(.van-list__finished-text){
    margin: 0 20px;
  }
}
.item{
  margin: 20px 20px 0;
  background: #fff;
  border-radius: 10px;

  .item-con{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 40px 23px 28px;
    box-sizing: border-box;
    position: relative;

    .item-con-c{
      width: 560px;

      .item-con-cell-d{
        width: 100%;
        font-size: 30px;
        font-weight: bold;
        color: #4F4F4F;
        @include not-wrap;
      }
      .item-con-cell-c{
        margin-top: 10px;
        font-size: 26px;
        color: #999999;
        @include not-wrap;
      }
    }
    .edit-pic{
      width: 32px;
      height: 32px;
    }
    .default-tips{
      position: absolute;
      right: 0;
      top: 0;
      width: 80px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      background: #1AA3FF;
      color: #fff; 
      font-size: 22px;
      border-radius: 0px 10px 0px 10px;
    }
  }
  .del-item{
    width: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FF6E6E;
    color: #fff;
    font-size: 30px;
    border-radius: 0px 10px 10px 0px;
  }

  :deep(.van-swipe-cell__right){
    right: -1px;
  }
}
.footer{
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px 29px 30px;
  box-sizing: border-box;
  background: #F6F6F6;
  @include safe-area-inset-bottom('40px');
  .add-btn{
    height: 88px;
    background: #1AA3FF;
    color: #fff;
    border-radius: 41px; 
    font-size: 36px;
    line-height: 88px;
    text-align: center;
    .add{
      display: inline-block;
      margin-left: 24px;
    }
  }
}
</style>