import axios from '@/lib/axios'

//添加收货地址
export const addressCreate = (data) => {
    return axios.request({
        url: 'api/addressCreate',
        data: data,
        method: 'post'
    });
};

//地址列表
export const addressList = (data) => {
    return axios.request({
        url: 'api/addressList',
        data: data,
        method: 'post'
    });
};

//修改地址
export const addressUpdate = (data) => {
    return axios.request({
        url: 'api/addressUpdate',
        data: data,
        method: 'post'
    });
};

//删除地址
export const addressDelete = (data) => {
    return axios.request({
        url: 'api/addressDelete',
        data: data,
        method: 'post'
    });
};

//地址详情
export const addressDetail = (data) => {
    return axios.request({
        url: 'api/addressDetail',
        data: data,
        method: 'post'
    });
};